<template>
    <v-container class="ma-0 pa-0">
        <div v-if="showTrimmings" class="text-center text-h5">
            <!-- <div><v-icon x-large left>mdi-cached</v-icon></div>
            <div>Order Lifecycle</div> -->
            <div><v-icon x-large left>mdi-pipe</v-icon></div>
            <div>{{ title }}</div>
        </div>
        <v-container class="overflow-x-auto overflow-y-hide flex-nowrap d-flex">
            <v-spacer />
            <v-edit-dialog>
                <v-btn x-small outlined :class="highlightApprove ? highlightClass : null">Approve</v-btn>
                
                <template v-slot:input>
                    <v-container style="max-width: 200px;" class="text-body-2">
                        <v-subheader>Order Approval</v-subheader>
                        <v-divider class="mb-4" />
                        <p>When a customer places a new order it needs to be approved by the supplier.</p>
                        <p>This can be done by BlitzIt Web automatically or manually by the supplier.</p>
                    </v-container>
                </template>
            </v-edit-dialog>

            <v-icon>mdi-arrow-right-thin</v-icon>
            
            <v-edit-dialog>
                <v-btn x-small outlined :class="highlightFulfill ? highlightClass : null">Fulfill</v-btn>
                
                <template v-slot:input>
                    <div>test</div>
                </template>
            </v-edit-dialog>
            
            <v-icon>mdi-arrow-right-thin</v-icon>
            
            <v-edit-dialog>
                <v-btn x-small outlined :class="highlightProcess ? highlightClass : null">Process</v-btn>
                
                <template v-slot:input>
                    <div>test</div>
                </template>
            </v-edit-dialog>
            
            <v-icon>mdi-arrow-right-thin</v-icon>
            
            <v-edit-dialog>
                <v-btn x-small outlined :class="highlightStockConsign ? highlightClass : null">Stock Consignment</v-btn>
                
                <template v-slot:input>
                    <div class="ma-2">
                        <div>test</div>
                        <div>Other Stuff</div>
                        <div>Here</div>
                    </div>
                </template>
            </v-edit-dialog>
            
            <v-icon>mdi-arrow-right-thin</v-icon>
            
            <v-edit-dialog>
                <v-btn x-small outlined :class="highlightPackageConsign ? highlightClass : null">Package Consignment</v-btn>
                
                <template v-slot:input>
                    <div>test</div>
                </template>
            </v-edit-dialog>
            
            <v-icon>mdi-arrow-right-thin</v-icon>
            
            <v-edit-dialog>
                <v-btn x-small outlined :class="highlightReadyForCourier ? highlightClass : null">Ready To Courier</v-btn>
                
                <template v-slot:input>
                    <div>test</div>
                </template>
            </v-edit-dialog>
            
            <v-icon>mdi-arrow-right-thin</v-icon>
            
            <v-edit-dialog>
                <v-btn x-small outlined :class="highlightRequestCourier ? highlightClass : null">Request Courier</v-btn>
                
                <template v-slot:input>
                    <div>test</div>
                </template>
            </v-edit-dialog>
            <v-spacer />
        </v-container>
    </v-container>
</template>

<script>
export default {
    name: 'BT-Order-Cycle-Header',
    props: {
        highlightApprove: {
            type: Boolean,
            default: false
        },
        highlightClass: {
            type: String,
            default: 'success--text'
        },
        highlightFulfill: {
            type: Boolean,
            default: false
        },
        highlightProcess: {
            type: Boolean,
            default: false
        },
        highlightStockConsign: {
            type: Boolean,
            default: false
        },
        highlightPackageConsign: {
            type: Boolean,
            default: false
        },
        highlightReadyForCourier: {
            type: Boolean,
            default: false
        },
        highlightRequestCourier: {
            type: Boolean,
            default: false
        },
        showTrimmings: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: 'Order Pipeline'
        }
    }
}
</script>